import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }

    initMenu() {
            new MetisMenu("#side-menu");

            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu");
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (this.props.location.pathname === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                this.activateParentDropdown(matchingMenuItem);
            }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        var role = localStorage.getItem("role")
        if(role === null) {
            role = sessionStorage.getItem("role")
        }

        var position = localStorage.getItem("position")
        if(position === null) {
            position = sessionStorage.getItem("position")
        }
        return (
            <React.Fragment>
                 {position === "Super Admin" ?
                 <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">

                     <li>
                        <Link className="waves-effect" to="dashboard">
                            <i className="fas fa-home mr-2"></i>{this.props.t('Dashboard')}
                        </Link>
                    </li>

                    {/* <li>
                        <Link className="waves-effect" to="import">
                            <i className="fas fa-upload mr-2"></i>{this.props.t('Import')}
                        </Link>
                    </li> */}

                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-check"></i>
                            <span>{this.props.t('Cases')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="verified-case">{this.props.t('Search Cases')}</Link></li>
                            <li><Link to="upload-case">{this.props.t('Upload Cases for Verification')}</Link></li>
                            <li><Link to="download-template">{this.props.t('Download FI Template')}</Link></li>
                            <li><Link to="create-case">{this.props.t('Create Case')}</Link></li>
                            <li><Link to="unassigned-case">{this.props.t('Search UnAssigned Cases')}</Link></li>
                            {/* <li><Link to="view-tat-details">{this.props.t('View TAT Details')}</Link></li> */}
                            <li><Link to="delete-old-case">{this.props.t('Delete Old Case')}</Link></li>
                        </ul>
                    </li>


                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-chart-pie"></i>
                            <span>{this.props.t('Reports')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="billing-report">{this.props.t('Billing Report')}</Link></li>
                            <li><Link to="pending-case">{this.props.t('Pending Cases')}</Link></li>
                            <li><Link to="attendance-report">{this.props.t('Attendance Report')}</Link></li>
                            <li><Link to="case-report">{this.props.t('Case Report')}</Link></li>
                            <li><Link to="search-case-report">{this.props.t('Search Case Report')}</Link></li>
                            <li><Link to="hp-case">{this.props.t('HP Case Report')}</Link></li>
                            <li><Link to="pending-hp-case">{this.props.t('Pending HP Case Reports')}</Link></li>
                            <li><Link to="verified-hp-case">{this.props.t('Verified HP Case Report')}</Link></li>
                            <li><Link to="agent-report">{this.props.t('Agent Report')}</Link></li>
                            <li><Link to="agent-realtime-report">{this.props.t('Agent Realtime Report')}</Link></li>
                            <li><Link to="agent-pending-report">{this.props.t('Agent Pending Report')}</Link></li> 
                            <li><Link to="sales-report">{this.props.t('Sales Report')}</Link></li> 
                            <li><Link to="bank-client-report">{this.props.t('Bank Client Report')}</Link></li> 
                            <li><Link to="sales-case">{this.props.t('Sales Case')}</Link></li> 
                        </ul>
                    </li>

                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-edit"></i>
                            <span>{this.props.t('Masters')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="bank">{this.props.t('Bank Name')}</Link></li>
                            <li><Link to="agent-approvals">{this.props.t('Agent Approvals')}</Link></li>
                            <li><Link to="agent-inactive">{this.props.t('Agent InActive')}</Link></li>
                            <li><Link to="agent-assign-state">{this.props.t('Agent Assign State')}</Link></li>
                            <li><Link to="loan-product">{this.props.t('Loan Product')}</Link></li>
                            <li><Link to="billing-price">{this.props.t('Billing Price')}</Link></li>
                            <li><Link to="push-notification">{this.props.t('Push Notification')}</Link></li>
                            <li><Link to="state">{this.props.t('State')}</Link></li>
                            <li><Link to="sales">{this.props.t('Sales')}</Link></li>
                            <li><Link to="sales-master">{this.props.t('Sales Master')}</Link></li>
                            {/* <li><Link to="send-mail">{this.props.t('Send Mail')}</Link></li> */}
                        </ul>
                    </li>

                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-user"></i>
                            <span>{this.props.t('Admin')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="add-user">{this.props.t('Manage Users')}</Link></li>
                            <li><Link to="send-mail">{this.props.t('Email Setup')}</Link></li>
                        </ul>
                    </li>


                    {/* <li>
                        <Link className="waves-effect" to="management">
                            <i className="fas fa-tasks mr-2"></i>{this.props.t('Management')}
                        </Link>
                    </li> */}


                    {/* <li>
                        <Link className="waves-effect" to="circle-management">
                            <i className="fas fa-briefcase mr-2"></i>{this.props.t('Circle Management')}
                        </Link>
                    </li> */}


                    {/* <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-users"></i>
                            <span>{this.props.t('Team Management')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="create-team">{this.props.t('Create Team')}</Link></li>
                            <li><Link to="list-team">{this.props.t('List Team')}</Link></li>
                            <li><Link to="approve-team">{this.props.t('Approve Team')}</Link></li>
                        </ul>
                    </li> */}


                    {/* <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-user-friends"></i>
                            <span>{this.props.t('User management')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="add-user">{this.props.t('User Creation')}</Link></li>
                        </ul>
                    </li> */}
                </ul>
            </div> 
            :

            <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">

            {role.includes("dash1") ?
                <li>
                    <Link className="waves-effect" to="dashboard">
                        <i className="fas fa-home mr-2"></i>{this.props.t('Dashboard')}
                    </Link>
                </li> : null }


                    <li>
                    {role.includes("case") ?
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-check"></i>
                            <span>{this.props.t('Cases')}</span>
                        </Link> : null }

                    
                        <ul className="sub-menu" aria-expanded="false">
                            {role.includes("case1") ?
                            <li><Link to="verified-case">{this.props.t('Search Cases')}</Link></li> : null }

                            {role.includes("case2") ?
                            <li><Link to="upload-case">{this.props.t('Upload Cases for Verification')}</Link></li> : null }
                            
                            {role.includes("case3") ?
                            <li><Link to="download-template">{this.props.t('Download FI Template')}</Link></li> : null }

                            {role.includes("case4") ?
                            <li><Link to="create-case">{this.props.t('Create Case')}</Link></li> : null }

                            {role.includes("case5") ?
                            <li><Link to="unassigned-case">{this.props.t('Search UnAssigned Cases')}</Link></li> : null }

                             {/* {role.includes("case6") ?
                            <li><Link to="view-tat-details">{this.props.t('View TAT Details')}</Link></li> : null } */}

                            {role.includes("case4") ?
                            <li><Link to="delete-old-case">{this.props.t('Delete Old Case')}</Link></li> : null }
                        </ul>
                    </li>

                


                <li>
                {role.includes("report") ?
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="fas fa-chart-pie"></i>
                        <span>{this.props.t('Reports')}</span>
                    </Link> : null }

                    <ul className="sub-menu" aria-expanded="false">
                        {role.includes("report1") ?
                           <li><Link to="billing-report">{this.props.t('Billing Report')}</Link></li> : null }

                        {role.includes("report2") ?
                        <li><Link to="pending-case">{this.props.t('Pending Cases')}</Link></li> : null }

                        {role.includes("report3") ?
                        <li><Link to="attendance-report">{this.props.t('Attendance Report')}</Link></li> : null }

                        {role.includes("report4") ?
                          <li><Link to="case-report">{this.props.t('Case Report')}</Link></li> : null }

                        {role.includes("report5") ?
                         <li><Link to="hp-case">{this.props.t('HP Case Report')}</Link></li> : null }

                        {role.includes("report6") ?
                        <li><Link to="pending-hp-case">{this.props.t('Pending HP Case Reports')}</Link></li> : null }

                        {role.includes("report7") ?
                       <li><Link to="verified-hp-case">{this.props.t('Verified HP Case Report')}</Link></li> : null }
                        
                        {role.includes("report8") ?
                        <li><Link to="agent-report">{this.props.t('Agent Report')}</Link></li> : null }

                        {role.includes("report9") ?
                        <li><Link to="agent-realtime-report">{this.props.t('Agent Realtime Report')}</Link></li> : null }

                        {role.includes("reportB") ?
                          <li><Link to="search-case-report">{this.props.t('Search Case Report')}</Link></li> : null }

                        {role.includes("reportA") ?
                        <li><Link to="agent-pending-report">{this.props.t('Agent Pending Report')}</Link></li> : null }

                        {role.includes("reportA") ?
                        <li><Link to="sales-report">{this.props.t('Sales Report')}</Link></li> : null }

                        {role.includes("reportA") ?
                        <li><Link to="bank-client-report">{this.props.t('Bank Client Report')}</Link></li> : null }

                        {role.includes("reportA") ?
                        <li><Link to="sales-case">{this.props.t('Sales Case')}</Link></li> : null }
                    </ul>
                </li>

                <li>
                    {role.includes("masters") ?
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="fas fa-edit"></i>
                        <span>{this.props.t('Masters')}</span>
                    </Link> : null }
                    <ul className="sub-menu" aria-expanded="false">
                        {role.includes("masters1") ?
                         <li><Link to="bank">{this.props.t('Bank Name')}</Link></li> : null }

                        {role.includes("masters2") ?
                          <li><Link to="agent-approvals">{this.props.t('Agent Approvals')}</Link></li> : null }

                        {role.includes("masters3") ?
                         <li><Link to="agent-inactive">{this.props.t('Agent InActive')}</Link></li> : null }

                        {role.includes("masters4") ?
                       <li><Link to="loan-product">{this.props.t('Loan Product')}</Link></li> : null }

                        {role.includes("masters5") ?
                        <li><Link to="billing-price">{this.props.t('Billing Price')}</Link></li> : null }

                        {role.includes("masters6") ?
                        <li><Link to="push-notification">{this.props.t('Push Notification')}</Link></li> : null }

                        {role.includes("masters7") ?
                        <li><Link to="state">{this.props.t('State')}</Link></li> : null }

                        {role.includes("masters7") ?
                        <li><Link to="sales">{this.props.t('Sales')}</Link></li> : null }

                        {role.includes("masters8") ?
                        <li><Link to="sales-master">{this.props.t('Sales Master')}</Link></li> : null }

                        {/* {role.includes("masters8") ?
                        <li><Link to="send-mail">{this.props.t('Send Mail')}</Link></li> : null } */}
                    </ul>
                </li>

                <li>
                {role.includes("user") ?
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="fas fa-user-friends"></i>
                        <span>{this.props.t('User management')}</span>
                    </Link> : null }
                    <ul className="sub-menu" aria-expanded="false">

                    {role.includes("user1") ?
                        <li><Link to="add-user">{this.props.t('Manage Users')}</Link></li> : null }

                    {role.includes("user2") ?
                          <li><Link to="send-mail">{this.props.t('Email Setup')}</Link></li> : null }
                    </ul>
                </li>
            </ul>
            </div> }

            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(SidebarContent));
