import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";


class Sales extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.submit = this.handleSubmit.bind(this);
    this.update = this.updateRow.bind(this);
    this.delete = this.delete.bind(this);
    this.funcMap = {
        '1': this.fetchList,
        '2': this.submit,
        '3': this.update,
        '4': this.delete
    };
    this.state = {
      firestoreData: [],
      banks: [],
      menu: 'Select',
      firebaseData: [],
      success_msg: false,
      update_msg: false,
      pushid: '',

      name: '',
      mobileNumber: '',
      emailId: '',
      password: '',
      bank: 'Select',
      branchName: '',
      location: ''
    };
  }

  componentDidMount() {
    let comp = this

    var config = {
      method: 'get',
      url: API_BASE_URL + '/auth/bank',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config)
    .then(function (response) {
      comp.setState({
        banks: response.data
      })
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });

    // firebase.firestore().collection("Sales").orderBy("Name")
    // .onSnapshot(function(querySnapshot) {
    //     var sales = [];
    //     querySnapshot.forEach(function(doc) {
    //       sales.push(doc.data())
    //     });
    //     comp.setState({
    //       firebaseData: sales
    //     })
    // });
    comp.fetchList()
  }

  async fetchList() {
    let comp = this

    var config = {
      method: 'get',
      url: API_BASE_URL + '/auth/sales',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config)
    .then(function (response) {
      comp.setState({
        firebaseData: response.data
      })
    })
    .catch(function (error) {
      console.log(error);
      // comp.handleNon200Response(error, "1");
    });
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  defaultState() {
    this.setState({
      success_msg: true,
      name: '',
      mobileNumber: '',
      emailId: '',
      password: '',
      bank: 'Select',
      branchName: '',
      location: '',
      menu: 'Select'
    })
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this


    if(data.name === '') {
        alert("Enter Name");
        document.getElementById("name").focus();
        return;
    }

    if(data.mobileNumber === '') {
        alert("Enter Mobile Number");
        document.getElementById("mobileNumber").focus();
        return;
    }

    if(data.mobileNumber.length !== 10) {
        alert("Enter Correct Mobile Number");
        document.getElementById("mobileNumber").focus();
        return;
    }

    if(data.emailId === '') {
        alert("Enter Email Id");
        document.getElementById("emailId").focus();
        return;
    }

    if(data.password === '') {
        alert("Enter Password");
        document.getElementById("password").focus();
        return;
    }

    if(data.bank === 'Select') {
        alert("Select Bank");
        document.getElementById("bank").focus();
        return;
    }

    if(data.branchName === '') {
        alert("Enter Branch Name");
        document.getElementById("branchName").focus();
        return;
    }

    if(data.location === '') {
        alert("Enter Location");
        document.getElementById("location").focus();
        return;
    }

    if(data.menu === 'Select') {
        alert("Select Status");
        document.getElementById("menu").focus();
        return;
    }

    var today = new Date()

    var jsonData = JSON.stringify({
      bank: data.bank,
      branch: data.branchName,
      emailId: data.emailId,
      location: data.location,
      mobileNumber: data.mobileNumber,
      name: data.name,
      password: data.password,
      status: data.menu,
      timestamp: today,
      userid: String(Date.now()),
    });

    var config = {
      method: 'post',
      url: API_BASE_URL + "/auth/sales",
      headers: { 
        'apikey': API_KEY, 
        'Authorization': 'Bearer ' + getCookie('at'),
        'Content-Type': 'application/json'
      },
      data : jsonData
    };

    axios(config)
    .then(function (response) {
      if(response.status == 201) {
        comp.setState({
          success_msg: true,
          name: '',
          mobileNumber: '',
          emailId: '',
          password: '',
          bank: 'Select',
          branchName: '',
          location: '',
          menu: 'Select'
      })
        comp.fetchList()
        // alert("Added Successfully")
      }
    })
    .catch(function (error) {
      console.log(error.response.data)
      // Check if the error is related to an already registered email
      if (error.response && error.response.data && error.response.data.message === "Email is already registered") {
        alert("Email is already registered.");
      } else {
        comp.handleNon200Response(error, "2")
      }
    });
    
    document.getElementById("create-form").reset()
} 

updateRow(e) {
  e.preventDefault();

    let data = this.state

    let comp = this

    if(data.name === '') {
        alert("Enter Name");
        document.getElementById("name").focus();
        return;
    }

    if(data.mobileNumber === '') {
        alert("Enter Mobile Number");
        document.getElementById("mobileNumber").focus();
        return;
    }

    if(data.mobileNumber.length !== 10) {
        alert("Enter Correct Mobile Number");
        document.getElementById("mobileNumber").focus();
        return;
    }

    if(data.emailId === '') {
        alert("Enter Email Id");
        document.getElementById("emailId").focus();
        return;
    }

    if(data.password === '') {
        alert("Enter Password");
        document.getElementById("password").focus();
        return;
    }

    if(data.bank === 'Select') {
        alert("Select Bank");
        document.getElementById("bank").focus();
        return;
    }

    if(data.branchName === '') {
        alert("Enter Branch Name");
        document.getElementById("branchName").focus();
        return;
    }

    if(data.location === '') {
        alert("Enter Location");
        document.getElementById("location").focus();
        return;
    }

    if(data.menu === 'Select') {
        alert("Select Status");
        document.getElementById("menu").focus();
        return;
    }

    var jsonData = JSON.stringify({
      bank: data.bank,
      branch: data.branchName,
      emailId: data.emailId,
      location: data.location,
      mobileNumber: data.mobileNumber,
      name: data.name,
      password: data.password,
      status: data.menu,
    });

    var config = {
      method: 'put',
      url: API_BASE_URL + "/auth/sales/" + data.dataId,
      headers: { 
        'apikey': API_KEY, 
        'Authorization': 'Bearer ' + getCookie('at'),
        'Content-Type': 'application/json'
      },
      data : jsonData
    };

    axios(config)
    .then(function (response) {
      if(response.status == 200) {
        comp.setState({
          update_msg: true,
          name: '',
          mobileNumber: '',
          emailId: '',
          password: '',
          bank: 'Select',
          branchName: '',
          location: '',
          menu: 'Select',
          pushid: '',
          dataId: ''
      })
        comp.fetchList()
        // alert("Updated Successfully")
      }
    })
    .catch(function (error) {
      console.log(error.response.data)
      comp.handleNon200Response(error, "2")
    });

  
  document.getElementById("create-form").reset()

  document.getElementById("submit").style.display = "block"
  document.getElementById("update").style.display = "none"

  document.getElementById("bank").disabled = false
}

handleNon200Response = (error, functionIndex) => {
  if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
    sessionExpired();
  } 
}

editRow(key) {
  let data = this.state
  let comp = this
  document.getElementById("submit").style.display = "none"
  document.getElementById("update").style.display = "block"

  document.getElementById("bank").disabled = true

  comp.setState({
    name: key.name,
    mobileNumber: key.mobileNumber,
    emailId: key.emailId,
    password: key.password,
    bank: key.bank,
    branchName: key.branch,
    location: key.location,
    menu: key.status,
    dataId: key._id
  })

  window.scrollTo('top', 0)
}

delete(key) {
  let comp = this
  if (window.confirm('Are you sure you want to  delete the sales?')) {
      var config = {
        method: 'delete',
        url:  API_BASE_URL + '/auth/sales/' + key._id,
        headers: { 
          'apikey': API_KEY,
          'Authorization': 'Bearer ' + getCookie('at')
        },
      };
    
      axios(config)
      .then(function (response) {
        if(response.status == 200) {
            comp.fetchList()
            alert("Deleted Successfully")
        }
      })
      .catch(function (error) {
        console.log(error)
        comp.handleNon200Response(error, "4");
      });
    } else {}
}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Masters" breadcrumbItem="Sales" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="name"
                                                          placeholder="Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="name"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.name}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Mobile Number<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="mobileNumber"
                                                          placeholder="Mobile Number"
                                                          type="number"
                                                          className="form-control"
                                                          id="mobileNumber"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.mobileNumber}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Email Id<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="emailId"
                                                          placeholder="Email Id"
                                                          type="text"
                                                          className="form-control"
                                                          id="emailId"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.emailId}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Password<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="password"
                                                          placeholder="Password"
                                                          type="password"
                                                          className="form-control"
                                                          id="password"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.password}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Bank<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.bank} id = "bank" name = "bank" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Bank</option>
                                                          {this.state.banks.map((rowData, index) => (
                                                            <option value = {rowData.name}>{rowData.name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Branch Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="branchName"
                                                          placeholder="Branch Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="branchName"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.branchName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Location<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="location"
                                                          placeholder="Location"
                                                          type="text"
                                                          className="form-control"
                                                          id="location"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.location}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Status<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select" id = "menu" name = "menu" value = {this.state.menu} onChange = {this.handleChange.bind(this)}>
                                                        <option value = "Select">Select Status</option>
                                                        <option value = "Active">Active</option>
                                                        <option value = "InActive">InActive</option>
                                                    
                                                    </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                                <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>
                                                <Button color="primary" onClick = {this.updateRow.bind(this)}  id = "update" style = {{display: "none"}} type="submit">Update</Button>
                                            {this.state.success_msg ? (
                                                  <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ success_msg: false }) }
                                                  >
                                                    Sales Successfully Created!
                                                  </SweetAlert>
                                                ) : null}
                                            {this.state.update_msg ? (
                                                  <SweetAlert
                                                    title="Great"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ update_msg: false })}
                                                  >
                                                    Sales Updated Successfully!
                                                  </SweetAlert>
                                                ) : null}     
                                                 
                                          </Form>
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>

                                        <Row>
                                          <Col>
                                          <Card>
                                            <CardBody>
                                              

                                          <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Name</th>
                                                            <th>Mobile Number</th>
                                                            <th>Email Id</th>
                                                            <th>Password</th>
                                                            <th>Bank</th>
                                                            <th>Branch</th>
                                                            <th>Location</th>
                                                            <th>Details</th>
                                                            <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.name}</td>
                                                        <td>{rowData.mobileNumber}</td>
                                                        <td>{rowData.emailId}</td>
                                                        <td>{rowData.password}</td>
                                                        <td>{rowData.bank}</td>
                                                        <td>{rowData.branch}</td>
                                                        <td>{rowData.location}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                        <td onClick = {this.delete.bind(this, rowData)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default Sales;