import React, { Component } from "react";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faInfo } from "@fortawesome/free-solid-svg-icons";
import Table from "reactstrap/lib/Table";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, sessionExpired } from "../../constants/utilis";

class SearchCaseReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      applicationId: '',
      totalRecords: 0
    };
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleNon200Response = (error) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
  }

  viewInfo(key) {
    sessionStorage.setItem("caseId", key._id);
    sessionStorage.setItem("fromComp", "case");
    window.open("/view-case");
  }

  searchByApplicationId() {
    let data = this.state;
    let comp = this;

    // var state = localStorage.getItem("state") || sessionStorage.getItem("state");
    let bank = localStorage.getItem("bank") || sessionStorage.getItem("bank");

    if(data.applicationId === '') {
      alert("Enter Application ID");
      document.getElementById("applicationId").focus();
      return;
    }

    let jsonData = {
      state: "SUPER ADMIN",
      applicationId: data.applicationId,
      bank: bank.split(",").slice(0, -1),
    };

    // if(!bank.includes("SUPER ADMIN")) {
    //   jsonData.bank = bank.split(",").slice(0, -1);
    // }

    let jsonData1 = JSON.stringify(jsonData);

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/case/searchByApplicationId',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };
    
    axios(config)
      .then(function (response) {
        if(response.status == 200) {
          comp.setState({
            firebaseData: response.data,
            totalRecords: response.data.length,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        comp.handleNon200Response(error);
      });
  }

  render() {
    let bank = "SUPER ADMIN"
    
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Report" breadcrumbItem="Search Case Report" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody id="user-list">
                    <Row>
                      <Col md={3} className="input-group">
                        <Input 
                          className="form-control" 
                          placeholder="Application ID"  
                          type="applicationId" 
                          name="applicationId" 
                          id="applicationId" 
                          value={this.state.applicationId}  
                          onChange={this.handleChange.bind(this)} 
                        />
                        <span className="input-group-append">
                          <button 
                            className="btn btn-outline-secondary" 
                            type="button" 
                            onClick={this.searchByApplicationId.bind(this)}
                          >
                            <FontAwesomeIcon style={{fontSize: "10px", cursor: "pointer"}} icon={faSearch} />
                          </button>
                        </span>
                      </Col>
                    </Row>
                    <br />
                    <div className="table-responsive" data-pattern="priority-columns">
                      <Table className="table mb-0 table-striped table-bordered" id="dataTable">
                        <thead>
                          <tr>
                            <th>Sl.No</th>
                            <th>Application Id</th>
                            <th>Customer Name</th>
                            <th>Bank</th>
                            <th>Product</th>
                            <th>Case Type</th>
                            <th>TAT Time</th>
                            <th>Agent</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.firebaseData.map((rowData, index) => (
                            bank.includes("SUPER ADMIN") || rowData.showToClient == "YES" ? (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{rowData.applicationid}</td>
                                <td>{rowData.customername}</td>
                                <td>{rowData.bank}</td>
                                <td>{rowData.productname}</td>
                                <td>{rowData.fi}</td>
                                <td>{rowData.tattime}</td>
                                <td>{rowData.agentname}</td>
                                <td>
                                  {rowData.status}
                                  {rowData.status === "Completed" && (
                                    <Row>
                                      <Col>
                                        <FontAwesomeIcon 
                                          style={{cursor: "pointer"}} 
                                          onClick={() => this.viewInfo(rowData)} 
                                          icon={faInfo} 
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                </td>
                              </tr>
                            ) : null
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchCaseReport;